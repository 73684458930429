import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLiveChatAgent, getLiveChatAgentId } from 'services/API';

export const getLiveChatAgentThunk = createAsyncThunk(
  'livechat/chats',
  async (
    {
      token,
      page,
      selectedColor,
      selectedStatus,
      searchId,
      isAfterDate,
      searchUser,
      isBeforeDate,
      project,
    },
    thunkAPI
  ) => {
    try {
      const response = await getLiveChatAgent(
        token,
        page,
        selectedColor,
        selectedStatus,
        searchId,
        isAfterDate,
        searchUser,
        isBeforeDate,
        project
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getLiveChatAgentIdThunk = createAsyncThunk(
  'livechat/agent',
  async ({ token, id }, thunkAPI) => {
    try {
      const response = await getLiveChatAgentId(token, id);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);
