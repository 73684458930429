import { createSlice } from '@reduxjs/toolkit';
import { getLiveChatAgentIdThunk, getLiveChatAgentThunk } from './qaActions';

const initialState = {
  chats: null,
  loading: null,
  error: null,
  agent: null,
};

const qaSlice = createSlice({
  name: 'qa',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getLiveChatAgentThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getLiveChatAgentThunk.fulfilled, (state, action) => {
        state.chats = action.payload;
        state.loading = false;
      })
      .addCase(getLiveChatAgentThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(getLiveChatAgentIdThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getLiveChatAgentIdThunk.fulfilled, (state, action) => {
        state.agent = action.payload;
        state.loading = false;
      })
      .addCase(getLiveChatAgentIdThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

  },
});

export default qaSlice.reducer;
