import { useState, useRef, useEffect, useCallback } from 'react';
import {
  ConteinerPaginate,
  DateInfo,
  IdInfo,
  List,
  PaginateButtonLeft,
  PaginateButtonRight,
  PaginateNumber,
  PenButton,
  PercentItem,
  Progress,
  ProgressBar,
  StatInformationBlock,
  StatsPageItem,
  StatsPageListConteiner,
  StatusInfo,
  TimeInfo,
  UserImg,
  UserInfoBlock,
  UserName,
} from './SupportVip.styled';
import testImg from '../../../images/testImg.png';
import SupportMenu from '../SupportMenu/SupportMenu';
import { useDispatch, useSelector } from 'react-redux';
import { getLiveChatAgentThunk } from '../../../redux/qa/qaActions';
import { selectAccessToken, selectQaChats } from '../../../redux/selectors';

const SupportVip = ({
  selectedColor,
  selectedStatus,
  searchId,
  searchUser,
  clickModal,
  isAfterDate,
  isBeforeDate,
  DeleteClick,
  selectedProject,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const token = useSelector(selectAccessToken);
  const chats = useSelector(selectQaChats);
  const chatsItem = chats?.results;

  // console.log(clickModal);

  const formatTime = time => {
    const [hoursPart, minutesPart, secondsPart] = time.split(':');
    const [seconds, milliseconds] = secondsPart.split('.');

    let hours = parseInt(hoursPart, 10);
    let minutes = parseInt(minutesPart, 10);
    let secondsFloat = parseFloat(`${seconds}.${milliseconds}`);

    if (secondsFloat >= 59.5) {
      minutes += 1;
    }

    if (minutes >= 60) {
      hours += 1;
      minutes = 0;
    }

    const displayHours = hours > 9 ? hours : `0${hours}`;
    const displayMinutes = minutes > 9 ? minutes : `0${minutes}`;

    const timeStr = `${displayHours} час ${displayMinutes} минуты`;

    return timeStr;
  };

  const formatDate = date => {
    const regex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2})/;
    const match = date.match(regex);

    if (match) {
      const year = match[1];
      const month = match[2];
      const day = match[3];
      const time = match[4];

      return `${day}/${month}/${year} | ${time}`;
    } else {
      console.log('Неверный формат даты');
    }
  };

  const toggleModal = userId => {
    setOpenModal(prevState => !prevState);
    setUserId(userId);
  };

  const handleClickOutside = event => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (openModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openModal]);

  useEffect(() => {
    setPage(1);
    setShouldFetch(true);
  }, [
    selectedStatus,
    selectedColor,
    clickModal,
    DeleteClick,
    selectedProject,
    searchUser,
    searchId,
    isAfterDate,
    isBeforeDate,
  ]);

  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      setIsLoading(true);
      try {
        await dispatch(
          getLiveChatAgentThunk({
            token,
            page,
            selectedColor,
            selectedStatus,
            searchId,
            isAfterDate,
            searchUser,
            isBeforeDate,
            project: parseInt(selectedProject),
            signal: controller.signal,
          })
        );
        setShouldFetch(false);
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Ошибка запроса:', error);
        }
      }
      setIsLoading(false);
    };

    if (shouldFetch && !clickModal) {
      fetchData();
    }

    return () => {
      controller.abort();
    };
  }, [
    page,
    selectedStatus,
    selectedColor,
    isAfterDate,
    isBeforeDate,
    clickModal,
    selectedProject,
    dispatch,
    shouldFetch,
    searchUser,
    searchId,
  ]);

  const handlePageChange = useCallback(
    newPage => {
      if (!isLoading) {
        setPage(newPage);
        setShouldFetch(true);
      }
    },
    [isLoading]
  );

  const calculateFalsePercentage = (trueCount, falseCount) => {
    const totalCount = trueCount + falseCount;
    if (totalCount === 0) {
      return 0;
    }
    const percentage = (falseCount / totalCount) * 100;
    return Math.round(percentage.toFixed(2));
  };

  return (
    <>
      <StatsPageListConteiner>
        <List>
          {chatsItem?.map(item => (
            <StatsPageItem key={item.thread_id}>
              <UserInfoBlock>
                {item.photo === null ? (
                  <UserImg src={testImg} />
                ) : (
                  <UserImg src={item.photo} />
                )}
                {item.username === null ? (
                  <UserName>Артём Ковальцев</UserName>
                ) : (
                  <UserName>{item.username}</UserName>
                )}
                <IdInfo>{item.thread_id}</IdInfo>
              </UserInfoBlock>
              <ProgressBar>
                <Progress
                  percent={calculateFalsePercentage(
                    item.score.true,
                    item.score.false
                  )}
                />
              </ProgressBar>
              <PercentItem>
                {calculateFalsePercentage(item.score.true, item.score.false)}%
              </PercentItem>
              <StatInformationBlock>
                {/* <StatusInfo>Завершён</StatusInfo> */}
                <DateInfo>{formatDate(item.created_at)}</DateInfo>
                <TimeInfo>{formatTime(item.chat_duration)}</TimeInfo>
              </StatInformationBlock>
              <PenButton onClick={() => toggleModal(item.pk)} />
            </StatsPageItem>
          ))}
        </List>
        <ConteinerPaginate>
          {chats?.previous === null || page === 1 ? null : (
            <PaginateButtonLeft
              onClick={() => handlePageChange(page - 1)}
              disabled={isLoading || page <= 1}
            />
          )}
          <PaginateNumber>{chatsItem?.length > 0 ? page : null}</PaginateNumber>
          {chats?.next === null ? null : (
            <PaginateButtonRight
              onClick={() => handlePageChange(page + 1)}
              disabled={isLoading}
            />
          )}
        </ConteinerPaginate>
      </StatsPageListConteiner>
      {openModal && (
        <div ref={modalRef}>
          <SupportMenu
            userId={userId}
            chats={chatsItem}
            calculateFalsePercentage={calculateFalsePercentage}
          />
        </div>
      )}
    </>
  );
};

export default SupportVip;
