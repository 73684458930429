import {
  HeaderCon,
  LogoText,
  LogoDot,
  TextContainer,
  SearchContainer,
  SearchIcon,
  Right,
  UserInfo,
  UserImg,
  UserText,
  UserTitle,
  UserSubTitle,
  StyledNav,
  UserCon,
  UserInfoTitle,
  LogOutBtn,
  LogOutIcon,
  RightCon,
  StartStatusBtn,
  GoOnLunchStatusBtn,
  GoOnBreakStatusBtn,
  StopStatusBtn,
  BackToWorkStatusBtn,
  SwitchButtonConteiner,
  GlobalStyles,
  Sun,
  Moon,
  HeaderConteiner,
  HeaderIconWrap,
  HeaderIcon,
  ChatsNameWrap,
  HeaderNameWrap,
  HeaderName,
  SelectWrap,
  AddFilterButton,
  Plus,
  FilterBlock,
  SelectedFiltersContainer,
  FilterTag,
  CloseFilterTag,
  LogOutBtnQa,
} from './Header.styled';
import userPhoto from '../../images/userPhoto.png';
// import MainInfo from './MainInfo/MainInfo';

import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserUsername,
  selectUserType,
  selectUserGrade,
  selectUserCurrency,
  selectUserDepartment,
  selectUserShift,
  selectAccessToken,
  selectUserConfig,
  selectUserPhoto,
  selectSwitcher,
} from '../../redux/selectors';
import { persistor } from '../../redux/store';
import { Notify } from 'notiflix';
import { postScheduleWorkerThunk } from '../../redux/schedule/scheduleOperations';
import { useEffect, useState } from 'react';
import { socket } from 'services/API';
import { postAntifraudShiftThunk } from '../../redux/fraud/fraudActions';
import {
  pauseTimer,
  resetTimer,
  resumeTimer,
  startTimer,
  stopTimer,
} from '../../redux/timer/timerSlice';
import { setSwitcher } from '../../redux/switcher/switcherAction';
import gotovVkalivat from '../../sound/gotov_vkalivat.mp3';
import zaChest from '../../sound/za_chest.mp3';
import MainIcon from '../../images/Union.png';
import useSound from 'use-sound';
import { useLocation } from 'react-router-dom';
import FilterMenu from 'components/QaPage/FilterMenu/FilterMenu';

const Header = ({
  setSelectedColor,
  setSelectedStatus,
  setSelectedProject,
  setSearchId,
  searchId,
  selectedColor,
  selectedStatus,
  selectedProject,
  setIsAfterDate,
  setIsBeforeDate,
  setSearchUser,
  searchUser,
  setClickModal,
  clickModal,
  setDeleteClick,
  isAfterDate,
  isBeforeDate,
}) => {
  const switcher = useSelector(selectSwitcher);
  const dispatch = useDispatch();
  const uname = useSelector(selectUserUsername);
  const utype = useSelector(selectUserType);
  const grade = useSelector(selectUserGrade);
  const currency = useSelector(selectUserCurrency);
  const shift = useSelector(selectUserShift);
  const token = useSelector(selectAccessToken);
  const depart = useSelector(selectUserDepartment);
  const config = useSelector(selectUserConfig);
  const uPhoto = useSelector(selectUserPhoto);
  const [isData, setData] = useState();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [playFromTbreak] = useSound(gotovVkalivat);
  const [playStart] = useSound(zaChest);
  const location = useLocation();
  const today = new Date().toISOString().split('T')[0];

  const DeleteDate = () => {
    setIsAfterDate('');
    setIsBeforeDate('');
    setDeleteClick(true);
  };

  const openModal = () => {
    setClickModal(true);
    setIsSettingsOpen(true);
    setDeleteClick(false);
  };

  const closeModal = () => {
    setClickModal(prev => !prev);
    setIsSettingsOpen(false);
  };

  const handleProjectChange = event => {
    setSelectedProject(event.target.value);
  };

  const handleColorChange = event => {
    setSelectedColor(event.target.value);
  };

  const handleStatusChange = event => {
    setSelectedStatus(event.target.value);
  };

  const openAddFilter = () => {
    setIsOpenFilter(prev => !prev);
  };

  const searchInputId = event => {
    const value = event.target.value;
    setSearchId(value);
  };

  const searchInputUser = event => {
    const value = event.target.value;
    setSearchUser(value);
  };

  const resetFilter = filterName => {
    if (filterName === 'color') {
      setSelectedColor('');
    } else if (filterName === 'status') {
      setSelectedStatus('');
    } else if (filterName === 'project') {
      setSelectedProject('');
    } else if (filterName === 'fullDate') {
      setIsAfterDate('');
      setIsBeforeDate('');
    } else if (filterName === 'agent') {
      setSearchUser('');
    } else if (filterName === 'id') {
      setSearchId('');
    } else if (filterName === 'date') {
      setIsAfterDate('');
    }
  };

  const groupItem = groupName => {
    if (groupName === '1') {
      return 'Gama';
    } else if (groupName === '2') {
      return 'Kent';
    } else if (groupName === '3') {
      return 'Cat';
    } else if (groupName === '4') {
      return 'R7';
    } else if (groupName === '5') {
      return 'Kometa';
    } else if (groupName === '6') {
      return 'Daddy';
    } else if (groupName === '7') {
      return 'Mers';
    } else if (groupName === '8') {
      return 'Spark';
    }
  };

  //Anti-Fraud, Supports
  const handleLogout = () => {
    console.log(depart);
    if (depart === 'Supports' || depart === 'Qa') {
      persistor.purge();
      sessionStorage.clear();
      window.location.reload();
      Notify.success('logout');
    }

    if (depart === 'Anti-Fraud') {
      if (isData?.start_working) {
        Notify.failure('Stop work shift');
      } else {
        persistor.purge();
        sessionStorage.clear();
        window.location.reload();
        Notify.success('logout');
      }
    }
  };

  useEffect(() => {
    // Запрос разрешения на уведомления при загрузке компонента
    Notification.requestPermission();

    const lunchStartTime = localStorage.getItem('lunchStartTime');
    if (lunchStartTime) {
      const startTime = parseInt(lunchStartTime, 10);
      const currentTime = new Date().getTime();
      const timeLeft = startTime + 10 * 60 * 1000 - currentTime; // 10 минут в миллисекундах

      if (timeLeft > 0) {
        // Восстановление таймера
        setTimeout(() => {
          new Notification('Время вернуться с обеда!', {
            body: 'Обеденный перерыв закончился.',
          });
          localStorage.removeItem('lunchStartTime'); // Очищаем время начала обеда
        }, timeLeft);
      }
    }
  }, []);

  useEffect(() => {
    const tbreakStartTime = localStorage.getItem('tbreakStartTime');
    if (tbreakStartTime) {
      const currentTime = new Date().getTime();
      const timePassed = currentTime - tbreakStartTime;
      const timeLeft = 10 * 60 * 1000 - timePassed; // 10 минут - прошедшее время

      if (timeLeft > 0) {
        setTimeout(() => {
          if (Notification.permission === 'granted') {
            new Notification('Время вернуться к работе!', {
              body: 'Перерыв закончился.',
            });
          }
          localStorage.removeItem('tbreakStartTime');
        }, timeLeft);
      } else {
        // Если время перерыва истекло, сразу удаляем его из хранилища
        localStorage.removeItem('tbreakStartTime');
      }
    }
  }, []);
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    Notification.requestPermission();
    let timeoutId; // Для отмены таймера

    const checkTimeAndShowButton = () => {
      const now = new Date(); // Текущее время

      // Конвертируем время начала и окончания перерыва в миллисекунды
      const startAt = new Date().setUTCHours(0, 0, shift?.tbreak?.start_at, 0);
      const endAt = new Date().setUTCHours(0, 0, shift?.tbreak?.end_at, 0);
      setShowButton(false);
      // Проверяем, находится ли текущее время в диапазоне перерыва
      if (now.getTime() >= startAt && now.getTime() <= endAt) {
        setShowButton(true);
      } else if (now.getTime() < startAt) {
        // Если до начала перерыва еще есть время, устанавливаем таймер
        timeoutId = setTimeout(() => {
          setShowButton(true);
          if (Notification.permission === 'granted') {
            new Notification('Время обеда!', {
              body: 'Вы можете уйти на обед.',
            });
          }
          // Затем устанавливаем таймер для скрытия кнопки после окончания перерыва
          const durationUntilEnd = endAt - now.getTime();
          setTimeout(() => {
            setShowButton(false);
          }, durationUntilEnd);
        }, startAt - now.getTime());
      } else {
        setShowButton(false);
      }
    };

    checkTimeAndShowButton();

    // Очистка таймера при размонтировании компонента
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [shift?.tbreak?.start_at, shift?.tbreak?.end_at]);

  const [showLunch, setShowLunch] = useState(false);
  useEffect(() => {
    let timeoutId; // Для отмены таймера

    const checkTimeAndShowButton = () => {
      const now = new Date(); // Текущее время

      // Конвертируем время начала и окончания перерыва в миллисекунды
      const startAt = new Date().setUTCHours(0, 0, shift?.lunch?.start_at, 0);
      const endAt = new Date().setUTCHours(0, 0, shift?.lunch?.end_at, 0);

      // Проверяем, находится ли текущее время в диапазоне перерыва
      if (now.getTime() >= startAt && now.getTime() <= endAt) {
        setShowLunch(true);
      } else if (now.getTime() < startAt) {
        // Если до начала перерыва еще есть время, устанавливаем таймер
        timeoutId = setTimeout(() => {
          setShowLunch(true);
          // Затем устанавливаем таймер для скрытия кнопки после окончания перерыва
          const durationUntilEnd = endAt - now.getTime();
          setTimeout(() => {
            setShowLunch(false);
          }, durationUntilEnd);
        }, startAt - now.getTime());
      } else {
        setShowLunch(false);
      }
    };

    checkTimeAndShowButton();

    // Очистка таймера при размонтировании компонента
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [shift?.lunch?.start_at, shift?.lunch?.end_at]);

  useEffect(() => {
    setData(shift);
  }, [shift]);

  const startBtn = async () => {
    if (depart === 'Anti-Fraud') {
      if (config?.shift_type === 'requests') {
        const res = await dispatch(
          postScheduleWorkerThunk({ token, scheduleData: { action: 'start' } })
        );
        if (res.meta.requestStatus === 'fulfilled') {
          socket.send(
            JSON.stringify({
              action: 'subscribe',
              payload: {
                channel: 'antifraud_requests',
                project: config?.project,
              },
            })
          );
        }
      }
      if (config?.shift_type !== 'requests' && config?.shift_type !== null) {
        const res = await dispatch(
          postScheduleWorkerThunk({ token, scheduleData: { action: 'start' } })
        );
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(startTimer());
        }
      }
    }
    if (depart === 'Supports') {
      const res = await dispatch(
        postScheduleWorkerThunk({ token, scheduleData: { action: 'start' } })
      );
      if (res.meta.requestStatus === 'fulfilled') {
        playStart();
      }
    }
  };

  const goOnLunchBtnSup = async () => {
    const res = await dispatch(
      postScheduleWorkerThunk({
        token,
        scheduleData: { action: 'on_lunch', value: isData?.lunch?.start_at },
      })
    );
    if (res.meta.requestStatus === 'fulfilled') {
      Notify.success('Иди отдыхать.', { timeout: 1000 });

      const startTime = new Date().getTime();
      localStorage.setItem('lunchStartTime', startTime.toString());

      setTimeout(() => {
        new Notification('Время вернуться с обеда!', {
          body: 'Обеденный перерыв закончился.',
        });
        localStorage.removeItem('lunchStartTime');
      }, 10 * 60 * 1000); // 10 минут
    } else {
      Notify.failure('Еще рано.', { timeout: 1000 });
    }
  };

  const goOnTBreakBtnSup = async () => {
    const res = await dispatch(
      postScheduleWorkerThunk({ token, scheduleData: { action: 'on_tbreak' } })
    );
    if (res.meta.requestStatus === 'fulfilled') {
      Notify.success('Иди отдыхать.', { timeout: 1000 });

      // Сохраняем время начала перерыва
      const startTime = new Date().getTime();
      localStorage.setItem('tbreakStartTime', startTime);

      // Устанавливаем таймер на 10 минут
      setTimeout(() => {
        // Проверяем, не вернулся ли пользователь с перерыва раньше времени
        if (localStorage.getItem('tbreakStartTime')) {
          if (Notification.permission === 'granted') {
            new Notification('Время вернуться к работе!', {
              body: 'Перерыв закончился.',
            });
          }
        }
      }, 10 * 60 * 1000); // 10 минут
    } else {
      Notify.failure('Еще рано.', { timeout: 1000 });
    }
  };

  const goFromTBreakBtnSup = async () => {
    const res = await dispatch(
      postScheduleWorkerThunk({
        token,
        scheduleData: { action: 'from_tbreak' },
      })
    );
    if (res.meta.requestStatus === 'fulfilled') {
      playFromTbreak();
      Notify.success('Работать)', { timeout: 1000 });

      // Удаляем время начала перерыва
      localStorage.removeItem('tbreakStartTime');
    } else {
      Notify.failure('Еще рано.', { timeout: 1000 });
    }
  };

  const goFromLunchBtnSup = async () => {
    const res = await dispatch(
      postScheduleWorkerThunk({ token, scheduleData: { action: 'from_lunch' } })
    );
    if (res.meta.requestStatus === 'fulfilled') {
      Notify.success('Время вернуться к работе!', { timeout: 1000 });
      localStorage.removeItem('lunchStartTime');
    } else {
      Notify.failure('Еще рано.', { timeout: 1000 });
    }
  };

  const goOnTBreakBtn = async () => {
    if (config?.shift_type === 'requests') {
      dispatch(
        postScheduleWorkerThunk({
          token,
          scheduleData: { action: 'on_tbreak' },
        })
      );
    }
    if (config?.shift_type !== 'requests' && config?.shift_type !== null) {
      const res = await dispatch(
        postScheduleWorkerThunk({
          token,
          scheduleData: { action: 'on_tbreak' },
        })
      );
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(pauseTimer());
      }
    }
  };

  const gofromTBreakBtn = async () => {
    if (config?.shift_type === 'requests') {
      dispatch(
        postScheduleWorkerThunk({
          token,
          scheduleData: { action: 'from_tbreak' },
        })
      );
    }
    if (config?.shift_type !== 'requests' && config?.shift_type !== null) {
      const res = await dispatch(
        postScheduleWorkerThunk({
          token,
          scheduleData: { action: 'from_tbreak' },
        })
      );
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(resumeTimer());
      }
    }
  };

  const stopBtn = async () => {
    if (config?.shift_type === 'requests') {
      const res = await dispatch(
        postScheduleWorkerThunk({
          token,
          scheduleData: { action: 'stop', value: false },
        })
      );
      if (res.meta.requestStatus === 'fulfilled') {
        socket.send(
          JSON.stringify({
            action: 'unsubscribe',
            payload: {
              channel: 'antifraud_requests',
              project: config?.project,
            },
          })
        );
      }
    }
    if (config?.shift_type !== 'requests' && config?.shift_type !== null) {
      const res = await dispatch(
        postScheduleWorkerThunk({
          token,
          scheduleData: { action: 'stop', value: false },
        })
      );
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(stopTimer());
        dispatch(resetTimer());
      }
    }
  };

  const backBtn = () => {
    const shiftData = {
      project: null,
      shift_type: null,
    };
    dispatch(postAntifraudShiftThunk({ shiftData, token }));
  };

  const handleSwitchChange = checked => {
    dispatch(setSwitcher(checked));
    localStorage.setItem('theme', checked ? 'dark' : 'light');
    document.documentElement.style.setProperty(
      '--pageBackgroundColor',
      checked ? '#2E2E2E' : '#FFFFFF'
    );
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    dispatch(setSwitcher(savedTheme === 'dark'));
    document.documentElement.style.setProperty(
      '--pageBackgroundColor',
      savedTheme === 'dark' ? '#2E2E2E' : '#FFFFFF'
    );
  }, []);

  return location.pathname === '/qa' ? (
    <HeaderConteiner>
      <HeaderIconWrap>
        <HeaderIcon src={MainIcon} alt="UserImg" />
      </HeaderIconWrap>

      {/* <HeaderNameWrap>
        <HeaderName>VIP SUPPORT</HeaderName>
      </HeaderNameWrap> */}

      <SelectWrap>
        <FilterBlock>
          <AddFilterButton onClick={openAddFilter}>
            <Plus /> Add Filter
          </AddFilterButton>
          <SelectedFiltersContainer>
            {selectedProject && (
              <FilterTag>
                {groupItem(selectedProject)}
                <CloseFilterTag onClick={() => resetFilter('project')} />
              </FilterTag>
            )}
            {selectedColor && (
              <FilterTag>
                {selectedColor}
                <CloseFilterTag onClick={() => resetFilter('color')} />
              </FilterTag>
            )}
            {selectedStatus && (
              <FilterTag>
                {selectedStatus}
                <CloseFilterTag onClick={() => resetFilter('status')} />
              </FilterTag>
            )}
            {isAfterDate && isBeforeDate && (
              <FilterTag>
                {isAfterDate} — {isBeforeDate}
                <CloseFilterTag onClick={() => resetFilter('fullDate')} />
              </FilterTag>
            )}
            {isAfterDate && isBeforeDate === '' && (
              <FilterTag>
                {isAfterDate} — {today}
                <CloseFilterTag onClick={() => resetFilter('date')} />
              </FilterTag>
            )}
            {searchUser && (
              <FilterTag>
                {searchUser}
                <CloseFilterTag onClick={() => resetFilter('agent')} />
              </FilterTag>
            )}
            {searchId && (
              <FilterTag>
                {searchId}
                <CloseFilterTag onClick={() => resetFilter('id')} />
              </FilterTag>
            )}
          </SelectedFiltersContainer>
          {isOpenFilter && (
            <FilterMenu
              closeMenu={openAddFilter}
              selectedProject={selectedProject}
              handleProjectChange={handleProjectChange}
              handleColorChange={handleColorChange}
              selectedColor={selectedColor}
              selectedStatus={selectedStatus}
              handleStatusChange={handleStatusChange}
              setIsOpenFilter={setIsOpenFilter}
              openModal={openModal}
              DeleteDate={DeleteDate}
              closeModal={closeModal}
              setIsAfterDate={setIsAfterDate}
              setIsBeforeDate={setIsBeforeDate}
              clickModal={clickModal}
              isSettingsOpen={isSettingsOpen}
              searchInputId={searchInputId}
              searchId={searchId}
              searchInputUser={searchInputUser}
              searchUser={searchUser}
            />
          )}
        </FilterBlock>

        <LogOutBtnQa onClick={handleLogout}>
          <LogOutIcon />
        </LogOutBtnQa>
      </SelectWrap>
    </HeaderConteiner>
  ) : (
    <>
      <GlobalStyles />
      <HeaderCon switcher={switcher}>
        <StyledNav to="/mainPage">
          <TextContainer>
            <LogoText switcher={switcher}>
              Cat
              <br /> Tools<LogoDot>.</LogoDot>
            </LogoText>
          </TextContainer>
        </StyledNav>

        <Right>
          {/* <SearchContainer>
            <SearchIcon switcher={switcher} />
            <SearchInput
              switcher={switcher}
              placeholder="Ищи всё что угодно..."
            ></SearchInput>
          </SearchContainer> */}
          <RightCon>
            {depart === 'Anti-Fraud' &&
              utype != 'tl' &&
              (isData?.can_start_working === true ? (
                <StartStatusBtn onClick={() => startBtn()}>
                  Start
                </StartStatusBtn>
              ) : (
                <></>
              ))}

            {depart === 'Anti-Fraud' &&
            utype != 'tl' &&
            isData?.can_pause_working === true &&
            isData?.start_working &&
            (isData?.tbreak?.is_active === false || isData?.tbreak === null) ? (
              <>
                <GoOnBreakStatusBtn onClick={() => goOnTBreakBtn()}>
                  Go on TBreak
                </GoOnBreakStatusBtn>
              </>
            ) : (
              <></>
            )}

            {depart === 'Anti-Fraud' &&
            utype != 'tl' &&
            isData?.tbreak?.is_active === true ? (
              <>
                <BackToWorkStatusBtn onClick={() => gofromTBreakBtn()}>
                  Back to work
                </BackToWorkStatusBtn>
              </>
            ) : (
              <></>
            )}

            {depart === 'Anti-Fraud' &&
            utype != 'tl' &&
            isData?.can_stop_working === true &&
            isData?.can_pause_working === true &&
            (isData?.tbreak?.is_active === false || isData?.tbreak === null) &&
            isData?.start_working ? (
              <>
                <StopStatusBtn onClick={() => stopBtn()}>Stop</StopStatusBtn>
              </>
            ) : (
              <></>
            )}

            {depart === 'Anti-Fraud' &&
            utype != 'tl' &&
            !isData?.start_working &&
            config?.project !== null &&
            config?.shift_type !== null ? (
              <>
                <StopStatusBtn onClick={() => backBtn()}>Back</StopStatusBtn>
              </>
            ) : (
              <></>
            )}

            {depart === 'Supports' &&
            utype != 'tl' &&
            isData?.can_start_working === true ? (
              <StartStatusBtn onClick={() => startBtn()}>Start</StartStatusBtn>
            ) : (
              <></>
            )}
            {depart === 'Supports' &&
            utype != 'tl' &&
            isData?.start_working === true ? (
              <>
                <StopStatusBtn onClick={() => stopBtn()}>Stop</StopStatusBtn>
              </>
            ) : (
              <></>
            )}
            {depart === 'Supports' &&
            utype != 'tl' &&
            isData?.can_pause_working === true &&
            isData?.lunch?.start_at !== null &&
            isData?.lunch?.is_active === false &&
            isData?.lunch?.is_expired === false &&
            showLunch === true ? (
              <GoOnLunchStatusBtn onClick={() => goOnLunchBtnSup()}>
                Go on Lunch
              </GoOnLunchStatusBtn>
            ) : (
              <></>
            )}

            {depart === 'Supports' &&
            utype != 'tl' &&
            isData?.can_pause_working === true &&
            isData?.tbreak?.start_at !== null &&
            isData?.tbreak?.is_active === false &&
            isData?.tbreak?.is_expired === false &&
            showButton === true ? (
              <GoOnBreakStatusBtn onClick={() => goOnTBreakBtnSup()}>
                Go on TBreak
              </GoOnBreakStatusBtn>
            ) : (
              <></>
            )}

            {depart === 'Supports' &&
            utype != 'tl' &&
            isData?.tbreak?.is_active === true ? (
              <GoOnBreakStatusBtn onClick={() => goFromTBreakBtnSup()}>
                From TBreak
              </GoOnBreakStatusBtn>
            ) : (
              <></>
            )}

            {depart === 'Supports' &&
            utype != 'tl' &&
            isData?.lunch?.is_active === true ? (
              <GoOnBreakStatusBtn onClick={() => goFromLunchBtnSup()}>
                From Lunch
              </GoOnBreakStatusBtn>
            ) : (
              <></>
            )}

            <SwitchButtonConteiner
              onClick={() => handleSwitchChange(!switcher)}
            >
              {switcher ? <Sun /> : <Moon />}
            </SwitchButtonConteiner>

            {/* {(isUser?.can_pause_working === true &&
            isUser?.lunch?.start_at !== null) ||
          (shift?.can_pause_working === true &&
            shift?.lunch?.start_at !== null) ? (
            <GoOnLunchStatusBtn onClick={() => goOnLunchBtn()}>
              Go on Lunch
            </GoOnLunchStatusBtn>
          ) : (
            <></>
          )}

          {(shift?.stop_working === null && shift?.can_stop_working === true) ||
          (isUser?.stop_working === null &&
            isUser?.can_stop_working === true) ? (
            <StopStatusBtn onClick={() => stopBtn()}>Stop</StopStatusBtn>
          ) : (
            <></>
          )} */}

            {/* <MainInfo /> */}
            <StyledNav to="/profile">
              <UserInfo>
                {!uPhoto && <UserImg src={userPhoto} alt="UserImg" />}
                {uPhoto && (
                  <UserImg
                    src={`http${
                      process.env.REACT_APP_SECURE === 'true' ? 's' : ''
                    }://${process.env.REACT_APP_BACKEND_URL}${uPhoto}`}
                    alt="UserImg"
                  />
                )}
                <UserText>
                  <UserTitle switcher={switcher}>{uname}</UserTitle>
                  <UserSubTitle>{utype}</UserSubTitle>
                </UserText>
                <UserCon>
                  <UserInfoTitle>Grade : {grade}</UserInfoTitle>
                  <UserInfoTitle>Balance : {currency}$</UserInfoTitle>
                </UserCon>
              </UserInfo>
            </StyledNav>
            <LogOutBtn onClick={handleLogout}>
              <LogOutIcon />
            </LogOutBtn>
          </RightCon>
        </Right>
      </HeaderCon>
    </>
  );
};

export default Header;
