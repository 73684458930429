export const logToLocalStorage = message => {
  const logs = JSON.parse(sessionStorage.getItem('logs')) || [];
  logs.push({ timestamp: new Date().toISOString(), message});
  sessionStorage.setItem('logs', JSON.stringify(logs));
};

export const downloadLogs = () => {
  const logs = JSON.parse(sessionStorage.getItem('logs')) || [];
  const pors = Object.fromEntries(Object.entries(JSON.parse(sessionStorage.getItem('persist:token'))).map(([key, value], index) => [key, JSON.parse(value)]))
 
  const LoginTime = localStorage.getItem('LoginTime');
  if (LoginTime) {
    logs.push({ timestamp: LoginTime, message: "Login Time" });
  }
  const together = logs.concat(pors);
  const blob = new Blob([JSON.stringify(together, null, 2)], {
    type: 'application/json',
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'logs.json';
  a.click();
  URL.revokeObjectURL(url);
};

export const clearLogs = () => {
  sessionStorage.removeItem('logs');
};
