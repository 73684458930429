import styled from 'styled-components';
import { FaPencilAlt } from 'react-icons/fa';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { FaArrowAltCircleLeft } from 'react-icons/fa';

export const SupportVipConteiner = styled.div``;

export const StatsPageListConteiner = styled.div`
  padding-left: 61px;
  padding-top: 50px;
`;

export const HeaderSupportVipConteiner = styled.div`
  padding-left: 61px;
  padding-top: 50px;

  width: 100%;
  border-bottom: 1px solid #dfdaeb;
`;

export const List = styled.ul``;

export const UserInfoBlock = styled.div`
  margin-left: 24px;
  display: flex;
  align-items: center;
`;

export const StatsPageItem = styled.li`
  border-bottom: 1px solid #dfdaeb;
  height: 48px;
  display: flex;
  align-items: center;
`;

export const UserName = styled.h1`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;

  color: #0a1019;

  width: 108px;

  margin-left: 5px;
`;

export const UserImg = styled.img`
  height: 24px;
  widht: 24px;
`;

export const IdInfo = styled.p`
  margin-left: 32px;

  width: 83px;

  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  padding: 5px;

  color: #ffffff;

  background-color: #7ec7fb;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProgressBar = styled.div`
  width: 220px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-left: 50px;
`;

export const Progress = styled.div`
  width: ${props => props.percent}%;
  height: 10px;
  background-color: ${props =>
    props.percent > 75 ? 'green' : props.percent > 50 ? 'yellow' : 'red'};
  transition: width 0.3s ease;
`;

export const PercentItem = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;

  width: 36px;

  margin-left: 20px;

  color: #0a1019;
`;

export const StatInformationBlock = styled.div`
  display: flex;
  align-items: center;

  margin-left: 282px;
`;

export const StatusInfo = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #8a8a8a;
  padding: 5px;

  background-color: #f7f4fe;
  border-radius: 8px;
`;

export const DateInfo = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #8a8a8a;
  padding: 5px;

  margin-left: 30px;

  background-color: #f7f4fe;
  border-radius: 8px;
`;

export const TimeInfo = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #8a8a8a;
  padding: 5px;

  margin-left: 30px;

  background-color: #f7f4fe;
  border-radius: 8px;
`;

export const PenButton = styled(FaPencilAlt)`
  width: 14px;
  height: 14px;

  margin-left: 50px;

  cursor: pointer;
`;

export const PaginateButtonRight = styled(FaArrowAltCircleRight)`
  width: 40px;
  height: 40px;
  margin-left: 40px;
  color: #7ec7fb;
  cursor: pointer;

  &:hover {
    color: #158154;
  }
`;

export const PaginateButtonLeft = styled(FaArrowAltCircleLeft)`
  width: 40px;
  height: 40px;
  margin-right: 40px;
  color: #7ec7fb;
  cursor: pointer;

  &:hover {
    color: #158154;
  }
`;

export const PaginateNumber = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 15px;

  color: #7ec7fb;
`;

export const ConteinerPaginate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 40px;
`;

export const ErrorText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;

  color: #0a1019;

  padding: 0;
`;
