import styled from 'styled-components';
import { CiSearch } from 'react-icons/ci';

export const SettingsContainer = styled.div`
  position: absolute;
  top: 45px;
  left: 0;
  width: 250px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
`;

export const DateBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateInput = styled.input`
  margin-top: 10px;
`;

export const Button = styled.button`
  width: 100%;
  background-color: #f0f0f0;
  border: none;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  text-align: left;

  &:hover {
    background-color: #e0e0e0;
  }
`;

export const CloseButton = styled.button`
  margin-top: 10px;
  padding: 10px;
  background-color: #e74c3c;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #c0392b;
  }
`;

export const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SearchButton = styled(CiSearch)`
  width: 25px;
  height: 25px;
  margin-right: 15px;
  cursor: pointer;
`;
