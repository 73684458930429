import {
  Calendar,
  Clock,
  Currency,
  ItemSideBar,
  ListConteiner,
  ListSideBar,
  NavLinkStyled,
  News,
  Person,
  Fraud,
  SideBarConteiner,
  TimeConteiner,
  Tools,
  LanchContainer,
  DataBase,
  Library,
  Statistic,
  QA,
  Archive,
  Qa,
} from './SideBar.styled';
import { useEffect, useRef, useState } from 'react';
import TimeWindow from 'components/TimeWindow/TimeWindow';
import {
  selectAccessToken,
  selectSwitcher,
  selectUserDepartment,
  selectUserGrade,
  selectUserShift,
  selectUserType,
} from '../../redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
  getScheduleLunchWorkerThunk,
  getScheduleTbreakWorkerThunk,
} from '../../redux/schedule/scheduleOperations';

const SideBar = ({ setChangePage }) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [open, setOpen] = useState(false);
  const dep = useSelector(selectUserDepartment);
  const location = useLocation();
  const targetRoute = '/fraudPage';
  const token = useSelector(selectAccessToken);
  const dispatch = useDispatch();
  const shift = useSelector(selectUserShift);
  const utype = useSelector(selectUserType);
  const isOnTargetRoute = location.pathname === targetRoute;
  const switcher = useSelector(selectSwitcher);
  const testQa = 'Qa';

  const handleFraudClick = () => {
    if (isOnTargetRoute) {
      window.location.reload();
    }
  };

  const menuRef = useRef();

  const handleClick = () => {
    setOpen(!open);
    if (!open) {
      dispatch(getScheduleLunchWorkerThunk({ token }));
      dispatch(getScheduleTbreakWorkerThunk({ token }));
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const handleClickOutside = event => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      !event.target.closest('.modal')
    ) {
      setOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const resolvedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formattedTime = currentTime.toLocaleTimeString('default', {
    timeZone: resolvedTimeZone,
    timeStyle: 'short',
  });

  const changePageQa = () => {
    setChangePage(false);
  };

  return (
    <SideBarConteiner>
      <ListConteiner>
        <ListSideBar>
          <ItemSideBar>
            <NavLinkStyled to="/" switcher={switcher}>
              <Person />
            </NavLinkStyled>
          </ItemSideBar>
          <ItemSideBar>
            <NavLinkStyled to="/schedule" switcher={switcher}>
              <Calendar />
            </NavLinkStyled>
          </ItemSideBar>
          <TimeWindow open={open} />
          {/* <ItemSideBar>
          <NavLinkStyled to="/news" switcher={switcher}>
            <News />
          </NavLinkStyled>
        </ItemSideBar>
        <ItemSideBar>
          <NavLinkStyled to="/tasks" switcher={switcher}>
            <Tools />
          </NavLinkStyled>
        </ItemSideBar> */}
          <ItemSideBar>
            <NavLinkStyled to="/homeDataBase" switcher={switcher}>
              <Library />
            </NavLinkStyled>
          </ItemSideBar>
          {dep === 'Qa' ? (
            <ItemSideBar>
              <NavLinkStyled to="/qa" switcher={switcher}>
                <Qa />
              </NavLinkStyled>
            </ItemSideBar>
          ) : (
            <></>
          )}

          {dep === 'Anti-Fraud' ? (
            <>
              <ItemSideBar>
                <NavLinkStyled to="/statistics" switcher={switcher}>
                  <Statistic />
                </NavLinkStyled>
              </ItemSideBar>
              <ItemSideBar onClick={() => handleFraudClick()}>
                <NavLinkStyled to="/fraudPage" switcher={switcher}>
                  <Fraud />
                </NavLinkStyled>
              </ItemSideBar>
              <ItemSideBar>
                <NavLinkStyled to="/archive" switcher={switcher}>
                  <Archive />
                </NavLinkStyled>
              </ItemSideBar>
            </>
          ) : (
            <></>
          )}

          {dep === 'Supports' ? (
            <>

              <ItemSideBar>
                <NavLinkStyled to="/cashback" switcher={switcher}>
                  <Currency />
                </NavLinkStyled>
              </ItemSideBar>
              {/* <ItemSideBar  onClick={() => changePageQa()}>
            <NavLinkStyled to="/QaPage" switcher={switcher}>
              <QA />
            </NavLinkStyled>
          </ItemSideBar> */}
            </>
          ) : (
            <></>
          )}

          {dep === 'Supports' &&
          shift?.can_pause_working === true &&
          shift?.start_working ? (
            <>
              <ItemSideBar onClick={handleClick} ref={menuRef}>
                <LanchContainer>
                  <Clock />
                </LanchContainer>
              </ItemSideBar>
            </>
          ) : (
            <></>
          )}
          {/* <ItemSideBar>
          <NavLinkStyled to="/database" switcher={switcher}>
            <DataBase/>
          </NavLinkStyled>
        </ItemSideBar> */}
        </ListSideBar>
      </ListConteiner>
      <TimeConteiner>{formattedTime}</TimeConteiner>
    </SideBarConteiner>
  );
};

export default SideBar;
