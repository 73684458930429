import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import { CiSearch } from 'react-icons/ci';
import { NavLink } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { IoIosSunny } from 'react-icons/io';
import { FaRegMoon } from 'react-icons/fa6';
import { FaPlus } from 'react-icons/fa';
import { IoIosCloseCircleOutline } from 'react-icons/io';

export const GlobalStyles = createGlobalStyle`
  :root {
    --pageBackgroundColor: #FFFFFF;
  }

  body {
    background-color: var(--pageBackgroundColor);
  }
`;

export const Sun = styled(IoIosSunny)`
  width: 25px;
  height: 25px;
  color: #b1b1b1;
`;

export const Moon = styled(FaRegMoon)`
  width: 25px;
  height: 25px;
  color: #b1b1b1;
`;

export const HeaderCon = styled.div`
  position: fixed;
  height: 64px;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid #d3d3d3;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${props => (props.switcher ? '#2E2E2E' : '#fff')};
  z-index: 999;
`;

export const TextContainer = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  padding-right: 29px;
  padding-left: 29px;
  border-right: 0.5px solid #d3d3d3;
`;

export const LogoText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 95%;
  color: ${props => (props.switcher ? '#fff' : '#000000')};
`;

export const LogoDot = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 95%;
  color: #1eaf69;
`;

export const SearchContainer = styled.div`
  margin-left: 81px;
  display: flex;
  align-items: center;
`;

export const SearchIcon = styled(CiSearch)`
  width: 24px;
  height: 19px;
  margin-right: 15px;
  color: ${props => (props.switcher ? '#fff' : '#000000')};
`;

// export const SearchInput = styled.input`
//   border: none;
//   outline: none;
//   background-color: ${props => (props.switcher ? '#2E2E2E' : '#fff')};
//   color: ${props => (props.switcher ? '#fff' : '#000000')};
// `;

export const Right = styled.div`
  width: 100%;
  display: flex;
  margin-right: 15px;
  justify-content: flex-end;
`;

export const SwitchButtonConteiner = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  padding-left: 35px;
  height: 37px;
  border-left: 0.5px solid #d3d3d3;
`;

export const UserImg = styled.img`
  width: 37px;
  height: 37px;
  margin-right: 20px;
  border-radius: 50%;
`;
export const UserText = styled.div`
  margin-right: 10px;
`;

export const UserTitle = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;

  color: ${props => (props.switcher ? '#fff' : '#000000')};
`;

export const UserSubTitle = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  color: #717171;
`;

export const StyledNav = styled(NavLink)`
  text-decoration: none;
`;

export const UserCon = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserInfoTitle = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #717171;
  margin: 6px;
`;

export const RightCon = styled.div`
  display: flex;

  align-items: center;
`;
export const LogOutBtn = styled.button`
  margin-left: 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding-right: 20px;
`;

export const LogOutBtnQa = styled.button`
  margin-left: 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding-right: 20px;

  right: 0;
  position: absolute;
`;

export const LogOutIcon = styled(FiLogOut)`
  width: 25px;
  height: 25px;
  color: #b1b1b1;
`;

export const StartStatusBtn = styled.button`
  margin-right: 60px;
  border: none;
  width: 69px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 2px;
  background: #afffb7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #1eaf69;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
`;

export const BackToWorkStatusBtn = styled.button`
  margin-right: 60px;
  border: none;
  width: 109px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 2px;
  background: #afffb7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #1eaf69;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
`;

export const GoOnLunchStatusBtn = styled.button`
  margin-right: 60px;
  border: none;
  width: 109px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 2px;
  background: #ffedaf;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #af751e;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
`;

export const GoOnBreakStatusBtn = styled.button`
  margin-right: 60px;
  border: none;
  width: 109px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 2px;
  background: #ffedaf;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #af751e;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
`;

export const StopStatusBtn = styled.button`
  margin-right: 60px;
  border: none;
  width: 69px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 2px;
  background: #f4a5a6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #fd3535;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
`;

export const HeaderConteiner = styled.div`
  position: fixed;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid #dfdaeb;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 99;
`;

export const HeaderIconWrap = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  padding-right: 39px;
  padding-left: 40px;
  border-right: 0.5px solid #d3d3d3;
`;

export const HeaderIcon = styled.img`
  width: 22px;
  height: 24px;
`;

export const ChatsNameWrap = styled.div`
  border-right: 0.5px solid #dfdaeb;

  height: 28px;
`;

export const HeaderNameWrap = styled.div`
  width: 300px;
  margin-left: 15px;
`;

export const HeaderInfoWrap = styled.div`
  width: 300px;
  height: 60px;
  border-left: 0.5px solid #dfdaeb;
`;

export const HeaderName = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;

  color: #717171;
`;

export const SearchInput = styled.input`
  width: 170px;
  padding: 8px;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  color: #333;

  &::placeholder {
    color: #999;
  }
`;

export const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
`;

export const FilterBlock = styled.div`
  position: relative;

  position: relative;
  display: flex;
  align-items: center;
`;

export const AddFilterButton = styled.button`
  background: none;
  border: none;
  color: #7ec7fb;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 4px;
  transition: color 0.3s ease, background-color 0.3s ease;
  width: 100px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;

  &:hover {
    color: #5ba6e2;
    background-color: rgba(126, 199, 251, 0.1);
  }

  &:active {
    color: #3f89c7;
  }
`;

export const Plus = styled(FaPlus)`
  width: 20px;
  height: 20px;
  color: inherit;
`;

export const SelectedFiltersContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 12px;
`;

export const FilterTag = styled.div`
  padding: 4px 8px;
  background-color: #eaf4fc;
  color: #333;
  border: 1px solid #7ec7fb;
  border-radius: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const FilterCloseIcon = styled.button`
  background: transparent;
  border: none;
  color: #7ec7fb;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    color: #5da6d8;
  }
`;

export const CloseFilterTag = styled(IoIosCloseCircleOutline)`
  width: 16px;
  height: 20px;

  cursor: pointer;
`;




