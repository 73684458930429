import styled from 'styled-components';
import { MdDateRange } from 'react-icons/md';
import { FaRegCalendarTimes } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa';

export const FilterMenuContainer = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  width: 200px;
  z-index: 10;
`;

export const FilterList = styled.ul`
  list-style: none;

  padding: 0;
  margin: 0;
`;

export const FilterItem = styled.li`
  padding: 8px 12px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: rgba(126, 199, 251, 0.1);
  }
`;

export const SelectInput = styled.select`
  padding: 10px;
  margin: 0 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  position: absolute;
  left: 206px;
  top: 0;

  &:focus {
    border-color: #6c63ff;
  }
`;

export const DateBlock = styled.div`
  padding: 6px;
  margin: 0 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  position: absolute;
  left: 206px;
  top: -2px;
`;

export const DateSettingsBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const DateSettingsIcon = styled(MdDateRange)`
  width: 30px;
  height: 30px;
  cursor: pointer;

  margin-right: 5px;
`;

export const DateSettingsDeleteIcon = styled(FaRegCalendarTimes)`
  width: 26px;
  height: 26px;
  cursor: pointer;
`;

export const SearchInput = styled.input`
  width: 200px;
  padding: 8px 12px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 12px;
  outline: none;
  font-size: 16px;
  color: #333;

  position: absolute;
  left: 216px;
  top: 0;
  transition: all 0.3s ease;

  &::placeholder {
    color: #bbb;
  }

  &:focus {
    background-color: #fff;
    border-color: #7ec7fb;
    box-shadow: 0 4px 8px rgba(126, 199, 251, 0.2);
    color: #333;
  }
`;

export const Plus = styled(FaPlus)`
  width: 12px;
  height: 12px;
  color: inherit;
`;
