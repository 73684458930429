import {
  AnalyticsBlock,
  AnalyticsItem,
  AnalyticsList,
  AnalyticsMainText,
  CommentText,
  CorrectItem,
  IdInformation,
  MainText,
  Minus,
  MistakeItem,
  PercentBarBlock,
  PercentItem,
  Plus,
  Progress,
  ProgressBar,
  ResultsBlock,
  SupportMenuConteiner,
  TextBlock,
  TextInformationBlock,
  User,
  UserBlock,
  UserGradeBlock,
  UserImg,
  UserJobTitle,
  UserName,
  SwitchBar,
  SwitchButton,
  ChatConteiner,
  SystemMessage,
  AgentMessage,
  CustomerMessage,
  ChatInfo,
  UserChatImg,
  UserChatName,
  TimeChat,
  MessageConteiner,
} from './SupportMenu.styled';
import imgTest from '../../../images/userPhoto.png';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLiveChatAgentIdThunk } from '../../../redux/qa/qaActions';
import {
  selectAccessToken,
  selectQaChatsAgent,
} from '../../../redux/selectors';

const SupportMenu = ({ userId, chats, calculateFalsePercentage }) => {
  const [openTextIndex, setOpenTextIndex] = useState(null);
  const [activeTab, setActiveTab] = useState('analytics');
  const dispatch = useDispatch();
  const token = useSelector(selectAccessToken);
  const agent = useSelector(selectQaChatsAgent);

  console.log(agent?.messages);

  const currentChat = chats.filter(item => item.pk === userId);
  const trueCount = currentChat[0]?.score?.true;
  const falseCount = currentChat[0]?.score?.false;

  const percentageOfFalse = calculateFalsePercentage(trueCount, falseCount);

  const toggleText = index => {
    setOpenTextIndex(prevIndex => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    dispatch(getLiveChatAgentIdThunk({ token, id: userId }));
  }, [userId]);

  const convertToHHMMSS = isoTime => {
    const date = new Date(isoTime);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <SupportMenuConteiner>
      <TextInformationBlock>
        <IdInformation>{currentChat[0]?.thread_id}</IdInformation>
      </TextInformationBlock>
      <UserGradeBlock>
        <User>
          {currentChat[0]?.photo === null ? (
            <UserImg src={imgTest} />
          ) : (
            <UserImg src={currentChat[0]?.photo} />
          )}
          <UserBlock>
            <UserJobTitle>Agent Name</UserJobTitle>
            {currentChat[0]?.username === null ? (
              <UserName>Артём Ковальцев</UserName>
            ) : (
              <UserName>{currentChat[0]?.username}</UserName>
            )}
          </UserBlock>
        </User>
        <PercentBarBlock>
          <PercentItem>Call Score {percentageOfFalse}%</PercentItem>
          <ProgressBar>
            <Progress percent={percentageOfFalse} />
          </ProgressBar>
        </PercentBarBlock>
        <ResultsBlock>
          <MistakeItem>Ошибок: {trueCount}</MistakeItem>
          <CorrectItem>Корректно: {falseCount}</CorrectItem>
        </ResultsBlock>
      </UserGradeBlock>

      <SwitchBar>
        <SwitchButton
          active={activeTab === 'analytics'}
          onClick={() => setActiveTab('analytics')}
        >
          Analytics
        </SwitchButton>
        <SwitchButton
          active={activeTab === 'chat'}
          onClick={() => setActiveTab('chat')}
        >
          Chat
        </SwitchButton>
      </SwitchBar>

      {activeTab === 'analytics' && (
        <AnalyticsBlock>
          <AnalyticsMainText>Analytics</AnalyticsMainText>
          <AnalyticsList>
            {agent?.results.map((item, index) => (
              <AnalyticsItem onClick={() => toggleText(index)} key={index}>
                <TextBlock>
                  {item.question}
                  {openTextIndex === index && (
                    <CommentText>{item.explanation}</CommentText>
                  )}
                </TextBlock>
                {item.decision === false ? <Plus /> : <Minus />}
              </AnalyticsItem>
            ))}
          </AnalyticsList>
        </AnalyticsBlock>
      )}
      {activeTab === 'chat' && (
        <>
          <AnalyticsMainText>Chat</AnalyticsMainText>
          <ChatConteiner>
            {agent?.messages.map((item, index) => (
              <div key={index}>
                {item.author.type === 'system' && (
                  <SystemMessage>
                    {item.text} {convertToHHMMSS(item.created_at)}{' '}
                  </SystemMessage>
                )}
                {item.author.type === 'agent' && (
                  <>
                    <ChatInfo side={'right'}>
                      <TimeChat side={'right'}>
                        {convertToHHMMSS(item.created_at)}
                      </TimeChat>
                      <UserChatName>{item.author.name}</UserChatName>
                      <UserChatImg side={'right'} src={imgTest} />
                    </ChatInfo>
                    <MessageConteiner side={'right'}>
                      <AgentMessage>{item.text}</AgentMessage>
                    </MessageConteiner>
                  </>
                )}
                {item.author.type === 'customer' && (
                  <>
                    <ChatInfo side={'left'}>
                      <UserChatImg side={'left'} src={imgTest} />
                      <UserChatName>{item.author.name}</UserChatName>
                      <TimeChat side={'left'}>
                        {convertToHHMMSS(item.created_at)}
                      </TimeChat>
                    </ChatInfo>
                    <MessageConteiner side={'left'}>
                      <CustomerMessage>{item.text}</CustomerMessage>
                    </MessageConteiner>
                  </>
                )}
              </div>
            ))}
          </ChatConteiner>
        </>
      )}
    </SupportMenuConteiner>
  );
};

export default SupportMenu;
