import styled from 'styled-components';
import { FaPlusSquare } from 'react-icons/fa';
import { FaSquareMinus } from 'react-icons/fa6';

export const SupportMenuConteiner = styled.div`
  background-color: #fff;
  width: 900px;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;

  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
`;

export const TextInformationBlock = styled.div`
  height: 60px;

  border-bottom: 1px solid #dfdaeb;

  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const MainText = styled.h1`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;

  color: #0a1019;
`;

export const IdInformation = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  width: 80px;

  padding: 5px;

  color: #ffffff;

  background-color: #7ec7fb;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserGradeBlock = styled.div`
  display: flex;
  align-items: revert-layer;
  margin-top: 25px;
  margin-left: 30px;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
`;

export const UserImg = styled.img``;

export const UserBlock = styled.div`
  margin-left: 7px;
`;

export const UserJobTitle = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 8px;
  line-height: 10px;

  color: #717171;

  margin: 0;
`;

export const UserName = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;

  margin-top: 5px;
  margin-bottom: 0;

  color: #0a1019;
`;

export const PercentBarBlock = styled.div`
  margin-left: 50px;
`;

export const PercentItem = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 8px;
  line-height: 10px;
  margin: 2px;

  color: #717171;
`;

export const ProgressBar = styled.div`
  width: 220px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
`;

export const Progress = styled.div`
  width: ${props => props.percent}%;
  height: 10px;
  background-color: ${props =>
    props.percent > 75 ? 'green' : props.percent > 50 ? 'yellow' : 'red'};
  transition: width 0.3s ease;
`;

export const ResultsBlock = styled.div`
  margin-left: 50px;

  display: flex;
  align-items: center;
`;

export const MistakeItem = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;

  color: #717171;
`;

export const CorrectItem = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;

  margin-left: 30px;

  color: #717171;
`;

export const AnalyticsBlock = styled.div`
  margin-top: 35px;
`;

export const AnalyticsMainText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  margin-left: 30px;

  color: #0a1019;
`;

export const AnalyticsList = styled.ul`
  list-style: auto;
`;

export const AnalyticsItem = styled.li`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;

  margin: 15px;

  color: #0a1019;

  cursor: pointer;

  width: 250px;
  display: flex;
  align-items: center;
`;

export const CommentText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;

  margin: 5px;

  color: #0a1019;
`;

export const TextBlock = styled.div``;

export const Plus = styled(FaPlusSquare)`
  width: 11px;
  height: 11px;

  color: #1eaf69;

  margin-left: 5px;
  flex-shrink: 0;
`;

export const Minus = styled(FaSquareMinus)`
  width: 11px;
  height: 11px;

  color: #e12a2a;

  margin-left: 5px;
  flex-shrink: 0;
`;

export const SwitchBar = styled.div`
  border-radius: 10px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 20px;
`;

export const SwitchButton = styled.button`
  background-color: ${({ active }) => (active ? '#7ec7fb' : '#ffffff')};
  color: ${({ active }) => (active ? '#ffffff' : '#000000')};
  border: ${({ active }) => (active ? 'none' : '1px solid #ccc')};
  border-radius: 5px;
  font-size: 10px;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #7ec7fb;
    color: #ffffff;
  }
`;

export const ChatConteiner = styled.div`
  display: flex;
  flex-direction: column;
    max-height: 430px;
  overflow-y: auto;
  padding: 50px;
  border-radius: 10px;
  width: 750px;
  margin-left: 30px;
  margin-right: 30px;
`;

export const SystemMessage = styled.div`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;

  color: #b7b0c7;

  text-align: center;
  margin: 10px 0;
  width: 100%;
`;

export const AgentMessage = styled.div`
  background-color: #efe9ff;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
  max-width: 70%;
  align-self: flex-end;

  font-family: 'Geologica';
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #0a1019;

  width: 340px;
`;

export const CustomerMessage = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
  max-width: 70%;
  align-self: flex-start;

  font-family: 'Geologica';
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #0a1019;
  border: 1px solid #f7f4fe;

  border: 1px solid #f7f4fe;
`;

export const ChatInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ side }) => (side === 'right' ? 'right' : 'left')};
  margin-top: 10px;

  position: relative;

  width: 100%;
`;

export const UserChatImg = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 10px;
  right: ${({ side }) => (side === 'right' ? '-35px' : 'unset')};
  left: ${({ side }) => (side === 'right' ? 'unset' : '-35px')};

  position: absolute;
  bottom: -30px;
`;

export const UserChatName = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #0a1019;

  padding: 0;
  margin: 0;
`;

export const TimeChat = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  margin-left: ${({ side }) => (side === 'right' ? '0' : '10px')};
  margin-right: ${({ side }) => (side === 'right' ? '10px' : '0')};
  margin-top: 0;
  margin-bottom: 0;

  color: #b7b0c7;
`;

export const MessageConteiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ side }) =>
    side === 'right' ? ' flex-end' : ' flex-start'};
`;
