import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  MainStatisticsSpan,
  MainStatisticsText,
  StatisticsSubText,
} from '../StatisticAgent/StatisticAgent.styled';
import { getFraudTeamStatisticsThunk } from '../../../redux/fraud/fraudActions';
import {
  selectUserUsername,
  selectAccessToken,
  selectUserConfig,
  selectFraudStatTeam,
  selectSwitcher,
  selectFraudLoading,
} from '../../../redux/selectors';
import {
  AgentsContainer,
  FlexBlock,
  InfoContainer,
  InfoPlace,
  LinkArrow,
  LinkButtonSup,
  NamePlace,
  NavLinkSup,
  OneAgent,
  Param,
  ParamConatiner,
  SearchAgent,
  SearchContainer,
  SearchImg,
  StatisticDateSuper,
  StatisticFilterConteiner,
  StatisticsSuperConteiner,
  ToggleLabel,
  ToggleSwitch,
  UpdateStat,
} from './StatisticsSuper.styled';
import G from '../../../images/G.png';
import { Notify } from 'notiflix';
import { Load } from 'components/App.styled';
import { MutatingDots } from 'react-loader-spinner';
import { useNavigate, useParams } from 'react-router-dom';

const StatisticsSuper = () => {
  const dispatch = useDispatch();
  const switcher = useSelector(selectSwitcher);
  const uname = useSelector(selectUserUsername);
  const uStat = useSelector(selectFraudStatTeam);
  const token = useSelector(selectAccessToken);
  const loading = useSelector(selectFraudLoading);
  const { project } = useParams();
  const { date } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const navigate = useNavigate();

  const config = useSelector(selectUserConfig);
  const proj = project || config.project;

  console.log(date);

  const handleInputChange = e => {
    setSearchValue(e.target.value);
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const monthDate = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear();
    return `${year}-${monthDate}-${day}`;
  };

  const [startDate, setStartDate] = useState(date || getCurrentDate());
  const [isToggleOn, setToggle] = useState(false);
  const dayType = isToggleOn ? 'night' : 'day ';

  const handleDateChange = event => {
    const selectedDate = event.target.value;
    setStartDate(selectedDate);
  };

  const handleReturnDate = () => {
    const currentDate = getCurrentDate();
    setStartDate(currentDate);
    console.log('Текущая дата:', currentDate);
  };

  const filteredStat = uStat?.filter(statTeam =>
    statTeam.username.toLowerCase().includes(searchValue.toLowerCase())
  );

  const filterTime = time => {
    const totalSeconds = Math.abs(time);

    const hours = Math.floor(totalSeconds / 3600);

    const minutes = Math.floor((totalSeconds % 3600) / 60);

    const seconds = Math.floor(totalSeconds % 60);

    const timeString = `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}`;

    return timeString;
  };

  const handleToggleChange = () => {
    setToggle(!isToggleOn);
  };

  useEffect(() => {
    const currentDate = getCurrentDate();

    if (proj !== null) {
      const fetchStatistics = () => {
        dispatch(
          getFraudTeamStatisticsThunk({
            token,
            project: proj,
            startDate,
            dayType,
          })
        );
      };

      fetchStatistics();
      setIsFirstLoad(false);

      if (currentDate === startDate) {
        const intervalId = setInterval(fetchStatistics, 10000);
        return () => clearInterval(intervalId);
      }
    } else {
      Notify.failure('Откройте смену для просмотра статистики.');
    }
  }, [dispatch, token, startDate, dayType, proj]);

  // console.log(startDate);

  return (
    <>
      {isFirstLoad && loading ? (
        <Load>
          <MutatingDots
            height="100"
            width="100"
            color="#AFFFB7"
            secondaryColor="#AFFFB7"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </Load>
      ) : (
        <StatisticsSuperConteiner>
          {project === undefined ? (
            <>
              <MainStatisticsText switcher={switcher}>
                Приветствую вас,
                <MainStatisticsSpan>{uname}!</MainStatisticsSpan>
              </MainStatisticsText>
              <StatisticFilterConteiner>
                <label htmlFor="date">Выберите дату:</label>
                <FlexBlock>
                  <input
                    type="date"
                    id="date"
                    name="date"
                    value={startDate}
                   onChange={handleDateChange} 
                  />
                  <ToggleSwitch>
                    <input
                      type="checkbox"
                      checked={isToggleOn}
                      onChange={handleToggleChange}
                    />
                    <span />
                  </ToggleSwitch>
                  <ToggleLabel>{isToggleOn ? 'Ночная' : 'Дневная'}</ToggleLabel>
                  <UpdateStat onClick={handleReturnDate} />
                </FlexBlock>
              </StatisticFilterConteiner>
            </>
          ) : (
            <>
              <LinkButtonSup onClick={() => navigate(-1)}>
                <LinkArrow /> Back
              </LinkButtonSup>
              <StatisticFilterConteiner>
                <label htmlFor="date">Выберите дату:</label>
                <FlexBlock>
                  <input
                    type="date"
                    id="date"
                    name="date"
                    value={startDate}
                    onChange={handleDateChange}
                  />
                  <ToggleSwitch>
                    <input
                      type="checkbox"
                      checked={isToggleOn}
                      onChange={handleToggleChange}
                    />
                    <span />
                  </ToggleSwitch>
                  <ToggleLabel>{isToggleOn ? 'Ночная' : 'Дневная'}</ToggleLabel>
                  <UpdateStat onClick={handleReturnDate} />
                </FlexBlock>
              </StatisticFilterConteiner>
            </>
          )}
          <StatisticsSubText switcher={switcher}>
            Статистика по проекту : {proj}
          </StatisticsSubText>
          <StatisticDateSuper>{startDate}</StatisticDateSuper>
          <SearchContainer>
            <SearchImg src={G} />
            <SearchAgent
              value={searchValue}
              onChange={handleInputChange}
              placeholder="Search by Agents"
            />
          </SearchContainer>
          {filteredStat?.length > 0 || uStat ? (
            <>
              <ParamConatiner>
                <Param switcher={switcher}>Кол-во обработанных заявок</Param>
                <Param switcher={switcher}>Ср время обработки заявок</Param>
                <Param switcher={switcher}>Время простоя</Param>
              </ParamConatiner>
              <AgentsContainer>
                {filteredStat.map(statTeam => (
                  <OneAgent key={statTeam?.username}>
                    <NavLinkSup
                      to={`/statistics/${statTeam?.pk}/${date || startDate}`}
                    >
                      <NamePlace>{statTeam?.username}</NamePlace>
                    </NavLinkSup>
                    <InfoContainer>
                      <InfoPlace switcher={switcher}>
                        {statTeam?.solved_requests}
                      </InfoPlace>
                      <InfoPlace switcher={switcher}>
                        {filterTime(statTeam?.average_solve_time)}
                      </InfoPlace>
                      <InfoPlace switcher={switcher}>
                        {filterTime(statTeam?.downtime)}
                      </InfoPlace>
                    </InfoContainer>
                  </OneAgent>
                ))}
              </AgentsContainer>
            </>
          ) : (
            <div>Информация отсутствует</div>
          )}
        </StatisticsSuperConteiner>
      )}
    </>
  );
};

export default StatisticsSuper;
